import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import _ from 'lodash'

import HomeLayout from '../components/HomeLayout';

import { HTMLContent } from '../components/Content'

import LocationPageTemplate from '../components/LocationPageTemplate'
import { useMetaOverride } from '../lib/hoc/useMeta';

const LocationPage = ({ data, pageContext }) => {
  const { markdownRemark: defaultPost } = data;
  const post = useMetaOverride('meta', defaultPost);

  const locMeta = _.find(_.castArray(_.get(post, 'frontmatter.areas')), { slug: pageContext.locPath });
  return (
    <HomeLayout headerText={_.get(post, 'frontmatter.description')}>
      <LocationPageTemplate
        contentComponent={HTMLContent}
        title={post.frontmatter.title}
        content={post.html}
        post={{
          frontmatter: {
            ...post.frontmatter,
            ...locMeta,
          },
        }}
        pageContext={pageContext}
      />
    </HomeLayout>
  )
}

LocationPage.propTypes = {
  data: PropTypes.object.isRequired,
}

export const homePageQuery = graphql`
  query LocationPage($locRegex: String) {
    markdownRemark(fields: { slug: { eq: "/meta/"}}) {
      html
      frontmatter {
        title
        description
        areas {
          title
          description
          slug
          actionText
          actionLink
        }
        categories {
          name
          title
          description
          className
          thumbnail {
            publicURL
          }
          slug
          airtable
        }
      }
    }
    allAirtable(filter: { data: { Area: { regex: $locRegex }}}) {
      edges {
        node {
          id
          table
          data {
            Zip_Code
            Notes
            Organization_Name
            City
            Created
            Last_Updated
            Area
            Days_Open
            Address
            Type_of_Service
            Hours
            Website
            Email
            Phone_Number
            Documents_Needed
            Link_to_Redeem
            Next_Steps
            Name_of_Service
            Description_of_Service
            Eligibility
            State
          }
        }
      }
    }    
  }
`

export default LocationPage;
